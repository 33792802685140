class Services {
  constructor() {
    // Storing dom elements
    this.$wrap = $('.js-services');

    if (this.$wrap.length === 0) {
      return;
    }

    this.$categories = this.$wrap.find('.js-services-category');
    this.$descriptionsWrap = this.$wrap.find('.js-services-descriptions-wrap');
    this.$descriptions = this.$wrap.find('.js-services-description');
    this.$mobileDescriptions = this.$wrap.find('.js-services-mobile-description');
    this.$fixedMenu = this.$wrap.find('.js-services-fixed-menu');
    this.$title = this.$wrap.find('.js-services-title');
    this.$window = $(window);

    // Initial state
    this.$activeContent = this.$descriptions.first();
    this.lastIndex = this.$descriptions.length - 1;
    this.currentCategory = false;
    this.scrollSpacing = 140;

    this.initialize();
  }

  initialize() {
    this.$categories.on('click', this.onCategoryClick.bind(this));
    this.$mobileDescriptions.on('click', this.onMobileDescriptionClick.bind(this));
    this.$window.on('scroll', this.onWindowScroll.bind(this)).trigger('scroll');
    this.$window.on('resize', this.onWindowResize.bind(this));
    window.setTimeout(() => this.$window.trigger('resize'));
  }

  showCategory(index) {
    this.$categories.removeClass('services-categories__item--active');

    if (this.isMobileDevice) {
      if (this.currentCategory !== index && index !== false) {
        this.$categories.eq(index).addClass('services-categories__item--active');
        this.currentCategory = index;
      }
      else {
        this.currentCategory = false;
      }
    }
    else {
      // We always want an active category on desktop view
      this.$categories.eq(index).addClass('services-categories__item--active');
      this.currentCategory = index;
    }

    this.hideMobileDescriptions();
    this.showDescription(index);
    this.setActive(index);
  }

  closeMobileDescription() {
    this.showCategory(false);
  }

  showDescription(index) {
    const $active = this.$descriptions.eq(index);
    let destination = 0;

    if ($active.length > 0 && !this.isMobileDevice) {
      if (index === 0) {
        destination = this.$wrap.offset().top;
      }
      else {
        destination = $active.offset().top - this.scrollSpacing;
      }
      this.scrollTo(destination + 1);
    }

    this.showMobileDescription(index);
  }

  scrollTo(scrollTop) {
    const properties = { scrollTop };
    const options = {
      complete: () => {
        this.isManuallyScrolling = false;
        this.setActive();
      }
    };

    this.isManuallyScrolling = true;
    $('html, body').animate(properties, options);
  }

  showMobileDescription(index) {
    this.hideMobileDescriptions();

    if (this.currentMobileDescription !== index && index !== false) {
      this.$mobileDescriptions.eq(index).addClass('services-mobile-description__items--active');
      this.currentMobileDescription = index;
    }
    else {
      this.currentMobileDescription = false;
    }
  }

  hideMobileDescriptions() {
    this.$mobileDescriptions.removeClass('services-mobile-description__items--active');
  }

  setActive() {
    let index = this.findCurrent();

    if ((!index && index !== 0) || this.isMobileDevice) {
      return;
    }

    if (this.isManuallyScrolling) {
      return;
    }

    this.$categories.removeClass('services-categories__item--active');
    this.$categories.eq(index).addClass('services-categories__item--active');

    this.$descriptions.removeClass('services-description__items--active');
    this.$descriptions.eq(index).addClass('services-description__items--active');
  }

  findCurrent() {
    if (!this.positions) {
      return 0;
    }

    if (this.scrollComplete) {
      return this.lastIndex;
    }

    let lowestDiffIndex;
    let lowestDiff = 0;

    this.positions.forEach(function(position, index) {
      const diff = this.$window.scrollTop() + this.scrollSpacing - position;

      if ((diff > 0 && (diff < lowestDiff || !lowestDiff))) {
        lowestDiff = diff;
        lowestDiffIndex = index;
      }
    }.bind(this));

    return lowestDiffIndex;
  }

  storeDescriptionPositions() {
    const getTop = (i, content) => $(content).offset().top;
    this.positions = this.$descriptions.map(getTop).toArray();
  }

  onCategoryClick(e) {
    e.preventDefault();

    const index = this.$categories.index($(e.currentTarget));
    this.showCategory(index);
  }

  onMobileDescriptionClick() {
    this.closeMobileDescription();
  }

  onWindowResize() {
    this.isMobileDevice = (this.$window.width() < 625);
    this.$descriptions.last().css({
      'min-height': this.$fixedMenu.height()
    });
    this.storeDescriptionPositions();
  }

  onWindowScroll() {
    const servicesTop = this.$wrap.offset().top - parseInt(this.$wrap.css('margin-top'), 10);
    const servicesHeight = this.$wrap.outerHeight(true);

    const windowTop = this.$window.scrollTop();
    const windowHeight = this.$window.height();

    this.scrollStart = windowTop >= servicesTop;
    this.scrollComplete = windowTop + windowHeight >= servicesTop + servicesHeight;

    this.$wrap.toggleClass('services--fixed', this.scrollStart && !this.scrollComplete);
    this.$wrap.toggleClass('services--fixed-bottom', this.scrollComplete);

    this.setActive();
  }
}

export default Services;
