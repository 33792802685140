const SLIDE_DURATION = 200;

class Team {
  constructor() {
    this.$wrap = $('.js-team');
    if (this.$wrap.length === 0) {
      return;
    }

    this.$team = $('.js-team-members');
    this.$members = this.$team.find('.js-team-member');
    this.$groupedBios = this.$team.find('.js-team-grouped-bio');
    this.slideDuration = SLIDE_DURATION;
    this.$window = $(window);

    this.initialize();
  }

  initialize() {
    this.$members.on('click', this.onMemberClick.bind(this));
    this.$groupedBios.on('click', this.onGroupedBioClick.bind(this));
    this.$window.on('scroll.team', this.onWindowScroll.bind(this)).trigger('scroll');
  }

  toggleMember(index) {
    this.toggleMemberState(index);
    this.toggleGroupedBio(index);
  }

  toggleMemberState(index) {
    const $active = this.$members.eq(index);

    this.$members.removeClass('member--active');

    if ($active && !$active.is(this.$activeMember)) {
      $active.addClass('member--active');
      this.$activeMember = $active;
      this.$team.addClass('team-members--bio-open');
    }
    else {
      this.$activeMember = false;
      this.$team.removeClass('team-members--bio-open');
    }
  }

  toggleGroupedBio(index) {
    const $active = this.$groupedBios.eq(index);
    const $old = this.$groupedBios.filter('.member-bios__item--active');
    const $activeWrap = $active.parents('.member-bios');
    const $oldWrap = $old.parents('.member-bios');

    // Bio already shown
    if ($old.length > 0) {
      // Clicking on already opened member
      if ($old.is($active)) {
        this.hideGroupedBio($active);
      }
      else {
        if ($activeWrap.is($oldWrap)) {
          this.slideDuration = 0;
        }

        // Hide old bio, show new one
        this.hideGroupedBio($old, function() {
          this.showGroupedBio($active);
        }.bind(this));
      }
    }
    else {
      // Nothing to hide, just show the bio
      this.showGroupedBio($active);
    }
  }

  hideGroupedBio($bio, onComplete = function() {}) {
    $bio.removeClass('member-bios__item--active');
    $bio.slideUp(this.slideDuration, onComplete);
  }

  showGroupedBio($bio) {
    $bio.addClass('member-bios__item--active');
    $bio.slideDown(this.slideDuration);
    this.slideDuration = SLIDE_DURATION;
  }

  onMemberClick(e) {
    const index = this.$members.index($(e.currentTarget));

    this.toggleMember(index);
  }

  onGroupedBioClick(e) {
    const index = this.$groupedBios.index($(e.currentTarget));

    this.toggleMember(index);
  }

  onWindowScroll() {
    const teamOffset = this.$wrap.offset().top;
    const shouldStick = this.$window.scrollTop() >= teamOffset;
    this.$wrap.toggleClass('team--fixed', shouldStick);
  }
}

export default Team;
