const CYCLE_DURATION = 2000;

class Partners {
  constructor() {
    this.$wrap = $('.js-partners');
    this.$logos = this.$wrap.find('.js-partner-logo');
    this.$window = $(window);
    this.numLogos = this.$logos.length;

    this.current = 0;

    this.initialize();
  }

  initialize() {
    this.handleInitialState();
    this.startCycling();
    this.$window.on('resize load', this.handleWindowResize.bind(this)).trigger('resize');
  }

  handleInitialState() {
    this.$logos.first().addClass('partner-logo--active');
  }

  startCycling() {
    window.setInterval(this.cycle.bind(this), CYCLE_DURATION);
  }

  cycle() {
    this.$logos.removeClass('partner-logo--active');

    this.current++;
    if (this.current >= this.numLogos) {
      this.current = 0;
    }

    this.$logos.eq(this.current).addClass('partner-logo--active');
  }

  handleWindowResize() {
    const heights = this.$logos.map(function() {
      return $(this).height();
    });
    const maxHeight = Math.max.apply(null, heights);
    this.$wrap.height(maxHeight);
  }
}

export default Partners;
