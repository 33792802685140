class Menu {
  constructor() {
    this.$body = $('body');
    this.$menu = $('.js-menu');
    this.$sticky = $('.js-menu__sticky');
    this.$stickyTriggerZone = $('.js-menu__sticky-trigger').last();
    this.$toggler = $('.js-menu-toggler');
    this.staticHeaderHeight = 0;
    this.$window = $(window);

    this.initialize();
  }

  initialize() {
    this.$body.on('click', this.onBodyClick.bind(this));
    this.$toggler.on('click', this.onTogglerClick.bind(this));
    this.staticHeaderHeight = this.$sticky.height();
    this.$window.on('scroll', this.onWindowScroll.bind(this)).trigger('scroll');
    window.setTimeout(() => this.$sticky.addClass('header-nav--initialized'));
  }

  onBodyClick() {
    this.$menu.removeClass('header--open');
  }

  onTogglerClick(e) {
    e.stopImmediatePropagation();
    this.$menu.toggleClass('header--open');
  }

  onWindowScroll(e) {
    const windowTop = this.$window.scrollTop();
    const $triggerZone = this.$stickyTriggerZone;
    let headerBottom = $triggerZone.offset().top + $triggerZone.outerHeight();

    // Prevent short header (without images) from being always condensed
    if (this.staticHeaderHeight < headerBottom) {
      // Remove header height to the headerBottom so the menu
      // is already condensed when it hits the "next" content
      headerBottom -= this.staticHeaderHeight;
    }

    const shouldStick = windowTop > headerBottom;

    this.$sticky.toggleClass('header-nav--condensed', shouldStick);
  }
}

export default Menu;
