class Implication {
  constructor() {
    this.$window = $(window);
    this.$wrap = $('.js-implication');

    if (this.$wrap.length === 0) {
      return;
    }

    this.$nav = this.$wrap.find('.js-implication-nav');
    this.$navItems = this.$nav.find('.js-implication-nav-item');
    this.navIds = this.$navItems.map((i, e) => $(e).data('nav')).toArray();
    this.$contents = this.$wrap.find('.js-implication-content');
    this.$title = this.$wrap.find('.js-implication-title');
    this.$indicator = this.$wrap.find('.js-implication-indicator');
    this.toneList = this.generateToneList();
    this.positions = [];
    this.initialize();
  }

  initialize() {
    this.bindScrollEvent();
    this.bindNavEvents();
    this.$window.on('resize', this.onWindowResize.bind(this)).trigger('resize');
  }

  bindScrollEvent() {
    this.$window.on('scroll.implication', this.onWindowScroll.bind(this)).trigger('scroll');
  }

  unbindScrollEvent() {
    this.$window.off('scroll.implication');
  }

  bindNavEvents() {
    this.$navItems.on('click', this.onNavClick.bind(this));
  }

  setActive() {
    let { index, navId } = this.findCurrent();
    const navIndex = this.navIds.indexOf(navId);

    if (!index && index !== 0) {
      return;
    }

    this.handleActiveContent(index);
    this.handleActiveNav(navIndex);
    this.handleActiveBackground(navIndex);
    this.moveIndicator(navIndex);
  }

  handleActiveContent(index) {
    this.$contents.removeClass('implication__content-item--active');
    this.$contents.eq(index).addClass('implication__content-item--active');
  }

  handleActiveNav(index) {
    this.$navItems.removeClass('implication-toc__item--active');
    this.$navItems.eq(index).addClass('implication-toc__item--active');
  }

  handleActiveBackground(index) {
    this.$wrap.removeClass(this.toneList);
    this.$wrap.addClass(`implication--tone-${index}`);
  }

  generateToneList() {
    let toneList = [];

    for (let i = 0; i < this.$contents.length; i++) {
      toneList.push(`implication--tone-${i}`);
    }

    return toneList.join(' ');
  }

  moveIndicator(index) {
    const itemHeight = parseInt(this.$navItems.outerHeight(true));
    this.$indicator.css('top', (itemHeight * index) + (itemHeight / 2));
  }

  findCurrent() {
    if (!this.positions || this.positions.length === 0) {
      return {};
    }

    const titleBottom = this.$title.offset().top + this.$title.outerHeight();
    let lowestDiffIndex = {};
    let lowestDiff = 0;

    this.positions.forEach(function(position, index) {
      let diff = titleBottom - position.top;

      if ((diff < 0 && (diff > lowestDiff || !lowestDiff))) {
        lowestDiff = diff;
        lowestDiffIndex = { index, navId: position.navId };
      }
    });

    return lowestDiffIndex;
  }

  storeImplicationContentsPosition() {
    let positions = [];

    const getTop = (i, content) => {
      const $content = $(content);
      return {
        top: $content.offset().top,
        navId: $content.data('nav')
      };
    };

    this.positions = this.$contents.map(getTop).toArray();
  }

  onWindowScroll() {
    // Handle upper limit
    const implicationOffset = this.$wrap.offset().top;
    const shouldStick = this.$window.scrollTop() >= implicationOffset;
    this.$wrap.toggleClass('implication--fixed', shouldStick);

    // Handle lower limit
    const spacer = (this.$window.height() * 0.2); // Represents .implication class top padding
    const navTop = shouldStick ? spacer + this.$window.scrollTop() : this.$nav.offset().top;
    const navBottom = navTop + this.$nav.outerHeight(true);
    const wrapBottom = this.$wrap.offset().top + this.$wrap.outerHeight(true);
    const shouldStickBottom = navBottom + spacer > wrapBottom;
    this.$wrap.toggleClass('implication--fixed-bottom', shouldStickBottom);

    // Set active content
    this.setActive();
  }

  onWindowResize() {
    this.storeImplicationContentsPosition();
  }

  onNavClick(e) {
    e.preventDefault();

    const index = this.$navItems.index($(e.currentTarget));
    const wrapPos = this.$wrap.offset().top;
    const parentPos = this.$contents.parent().position().top;
    const currentPos = this.$contents.eq(index).position().top;
    const pos = wrapPos - parentPos + currentPos;

    $('html, body').animate({
      scrollTop: pos
    });
  }
}

export default Implication;
