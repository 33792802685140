const CYCLE_DURATION = 5000;

class Welcome {
  constructor() {
    this.$body = $('body');
    this.$wrap = $('.js-tagline-looper');
    this.count = 0;

    if (this.$wrap.length === 0) {
      return;
    }

    this.$taglines = this.$wrap.find('.js-tagline-looper-title');
    this.$mainTagline = this.$taglines.filter('.tagline-looper__title--blurred');
    this.$blurredTagline = this.$taglines.not('.tagline-looper__title--blurred');
    this.initialize();
  }

  initialize() {
    this.startCycling();
  }

  startCycling() {
    window.setInterval(this.cycle.bind(this), CYCLE_DURATION);
  }

  cycle() {
    var animClass = 'tagline-looper__title--anim';
    var unblurClass = `${animClass}-unblur`;
    var blurClass = `${animClass}-blur`;

    if (this.count === 0) {
      this.$mainTagline.toggleClass(unblurClass);
      this.$blurredTagline.toggleClass(blurClass);
    }
    else {
      this.$taglines.toggleClass(`${unblurClass} ${blurClass}`);
    }

    this.$wrap.toggleClass('tagline-looper--alternate');
    this.count++;
  }
}

export default Welcome;
