const WATER_COLOR = '#c9c9c9';

const MAP_STYLES = [
  {
    elementType: 'geometry',
    stylers: [{ 'color': '#f5f5f5' }]
  },
  {
    elementType: 'labels.icon',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#616161' }]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ 'color': '#f5f5f5' }]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#003562' }]
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#bdbdbd' }]
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [{ 'color': '#f9f9f9' }]
  },
  {
    featureType: 'poi',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ 'color': '#eeeeee' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#757575' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ 'color': '#e5e5e5' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#9e9e9e' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ 'color': '#fffffff' }]
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#757575' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ 'color': '#dadada' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#616161' }]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#9e9e9e' }]
  },
  {
    featureType: 'transit',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ 'color': '#e5e5e5' }]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ 'color': '#eeeeee' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ 'color': WATER_COLOR }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#9e9e9e' }]
  }
];

const MARKER_STYLES = {
  path: 'M29.949,28.000 L30.000,28.000 L29.681,28.294 C28.944,29.121 28.129,29.874 27.244,30.544 L17.000,40.000 L6.756,30.544 C5.871,29.874 5.056,29.121 4.319,28.294 L4.000,28.000 L4.051,28.000 C1.529,25.034 0.000,21.198 0.000,17.000 C0.000,7.611 7.611,0.000 17.000,0.000 C26.389,0.000 34.000,7.611 34.000,17.000 C34.000,21.198 32.471,25.034 29.949,28.000 Z',
  fillColor: '#003562',
  fillOpacity: 1,
  strokeWeight: 1,
  strokeColor: '#ffffff'
};

class Contact {
  constructor() {
    this.offices = {};
    this.markers = [];
    this.$map = document.getElementById('offices-map');

    if (!this.$map) {
      return;
    }

    this.initialize();
  }

  initialize() {
    $(document).on('contact:mapload', this.handleMapLoad.bind(this));
    $(window).on('resize', this.handleWindowResize.bind(this));
  }

  handleMapLoad(event, { offices }) {
    const mapOptions = {
      styles: MAP_STYLES,
      backgroundColor: WATER_COLOR
    };

    this.offices = offices;
    this.map = new google.maps.Map(this.$map, mapOptions);
    this.markerBounds = new google.maps.LatLngBounds();
    this.displayLocationMarkers();
  }

  triggerFitBounds() {
    this.map.fitBounds(this.markerBounds);
  }

  displayLocationMarkers() {
    let icon = MARKER_STYLES;
    icon.anchor = new google.maps.Point(17, 40);

    let markers = this.markers;
    const map = this.map;

    Object.values(this.offices).forEach(({ lat, lng, link, size }) => {
      const position = new google.maps.LatLng(lat, lng);
      const clickable = !!link;
      icon.scale = (size === 'small') ? 0.6 : 1;

      let marker = new google.maps.Marker({ position, map, icon, clickable });
      if (link) {
        marker.addListener('click', () => {
          window.open(link, '_blank');
        });
      }
      markers.push(marker);
      this.markerBounds.extend(position);
    }, this);

    this.triggerFitBounds();
  }

  handleWindowResize(e) {
    this.triggerFitBounds();
  }
}

export default Contact;
