import 'jquery';
import Welcome from '../javascripts/welcome';
import Menu from '../javascripts/menu';
import Implication from '../javascripts/implication';
import Services from '../javascripts/services';
import Team from '../javascripts/team';
import Partners from '../javascripts/partners';
import Contact from '../javascripts/contact';

$(document).ready(() => {
  new Menu();
  new Welcome();
  new Implication();
  new Services();
  new Team();
  new Partners();
  new Contact();
});
